export type FileData = {
    hash?: string;
    mimetype?: string;
    name: string;
    size?: number;
    url?: string;
    uuid?: string;
};

export type FileResource = {
    type: string;
    id: string;
    attributes: {
        hash: string;
        mimetype: string;
        name: string;
        path: string;
        size: number;
        url: string;
    };
};

export type FilesResourceCollection = {
    data: FileResource[];
};

export const OFileType = {
    csv: {
        label: 'csv',
        type: 'text/csv',
    },
    doc: {
        label: 'doc',
        type: 'application/msword',
    },
    docx: {
        label: 'docx',
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    eml: {
        label: 'eml',
        type: 'message/rfc822',
    },
    html: {
        label: 'html',
        type: 'text/html',
    },
    jpeg: {
        label: 'jpeg',
        type: 'image/jpeg',
    },
    msg: {
        label: 'msg',
        type: 'application/vnd.ms-outlook',
    },
    odp: {
        label: 'odp',
        type: 'application/vnd.oasis.opendocument.presentation',
    },
    ods: {
        label: 'ods',
        type: 'application/vnd.oasis.opendocument.spreadsheet',
    },
    odt: {
        label: 'odt',
        type: 'application/vnd.oasis.opendocument.text',
    },
    pdf: {
        label: 'pdf',
        type: 'application/pdf',
    },
    png: {
        label: 'png',
        type: 'image/png',
    },
    ppt: {
        label: 'ppt',
        type: 'application/vnd.ms-powerpoint',
    },
    pptx: {
        label: 'pptx',
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
    rtf: {
        label: 'rtf',
        type: 'application/rtf',
    },
    txt: {
        label: 'txt',
        type: 'text/plain',
    },
    xls: {
        label: 'xls',
        type: 'application/vnd.ms-excel',
    },
    xlsx: {
        label: 'xlsx',
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    xml: {
        label: 'xml',
        type: 'application/xhtml+xml',
    },
    zip: {
        label: 'zip',
        type: 'application/zip',
    },
} as const;

export type FileType = (typeof OFileType)[keyof typeof OFileType];
